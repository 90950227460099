/*
  Add FCP Count Up Functionality
*/

export function addFCPCountUp() {
  countUpSection('.count-up-section .stat-item-wrp');
}


function countUpSection(element) {
  const threshold = 300;

  const checkVisibilityAndAnimate = ($el) => {
      const eTop = $el.offset().top; // Element's offset from the top
      const elHeight = $el.outerHeight();
      const viewportHeight = $(window).height();
      const scrollPos = $(window).scrollTop();

      // Check if the element is within the threshold
      if (
          Math.abs(scrollPos - (eTop + elHeight / 2 - viewportHeight / 2)) < threshold &&
          !$el.hasClass('calculated')
      ) {
          $el.addClass('calculated');
          setTimeout(() => {
              for (let i = 1; i < 5; i++) {
                  $el.find(`.final-${i}`).removeClass('d-none');
                  $el.find(`.initial-${i}`).addClass('d-none');
              }
              countUp($el); // Call countUp function
          }, 200);
      }
  };

  $(element).each(function () {
      checkVisibilityAndAnimate($(this));
  });

  $(window).on('scroll', function () {
      $(element).each(function () {
          checkVisibilityAndAnimate($(this));
      });
  });
}

function countUp(element) {
  element.find('.count').each(function () {
    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
            now = Number(Math.ceil(now)).toLocaleString('en');
            $(this).text(now);
          },
        }
      );
    }
  );
}
