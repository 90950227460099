/*
  Add functionality to Services Section
*/

export function addServicesFunctionality() {
  function adjustTabLayout($button) {
    const tabItem = $button.parent();
    const tabSection = $button.parent().parent();
    const isActive = $button.hasClass('active');
    const hasSubpages = $button.siblings('.tab-content').find('.subpages-wrp-col').length > 0;


    $('.tab-item').css('height', 'auto');
    $('.tab-btn').removeClass('active');
    $('.active .subpages-wrp-col').removeClass('fadeIn').addClass('fadeOut');
    $('.tab-content').removeClass('active');


    if (!isActive) {
      const tabContentHeight = $button.siblings('.tab-content').outerHeight(true);
      const totalAddHeight = tabContentHeight + $button.outerHeight(true);
      const tabItemWidth = tabItem[0].getBoundingClientRect().left;
      const tabSectionWidth = tabSection[0].getBoundingClientRect().left;
      const transformValue = -1 * (tabItemWidth - tabSectionWidth);

      if(hasSubpages) {
        $button.addClass('active');
        $button.siblings('.tab-content').addClass('active');
        $button.siblings('.tab-content.active').find('.subpages-wrp-col').removeClass('fadeOut').addClass('fadeIn');
        tabItem.css('height', totalAddHeight);
        $button.siblings('.tab-content').css('transform', `translateX(${transformValue}px)`);
      }
    }
  }

  function addClassesServices() {
    $('.cards-section.services').each(function () {
      const $div = $(this).find('.tabs-container');
      const $children = $div.children();
      let sliceCount = 0;

      if (matchMedia('screen and (max-width: 991px)').matches) {
        sliceCount = 1;
      } else if (matchMedia('(min-width: 992px) and (max-width: 1199px)').matches) {
        sliceCount = $children.length % 2 === 0 ? 2 : 1;
      } else if (matchMedia('screen and (min-width: 1200px)').matches) {
        sliceCount = $children.length % 3 === 0 ? 3 : $children.length % 3;
      }

      $children.removeClass('remove-padding');
      if (sliceCount > 0) {
        $children.slice(-sliceCount).addClass('remove-padding');
      }
    });
  }

  $('.tab-btn').on('click', function () {
    adjustTabLayout($(this));
  });

  // $(window).on('resize', function () {
  //   const $activeButton = $('.tab-btn.active');
  //   if ($activeButton.length) {
  //     adjustTabLayout($activeButton);
  //   }

  //   addClassesServices();
  // });

// get initial load device width
var dwidth = $(window).width();

$(window).on('resize', function () {
  // get device width when resize fires
  var wwidth = $(window).width();

  // check if the initial device width over resize width has being changed
  if(dwidth!==wwidth){
    // dwidth = $(window).width();
    console.log('Width changed');
    const $activeButton = $('.tab-btn.active');
    if ($activeButton.length) {
      adjustTabLayout($activeButton);
    }

    addClassesServices();
  }
});

  $(window).on('load', function () {
    addClassesServices();
  });
}