/* Logo section grid slider */

export function newsSectionGridSlider() {
  const slideshowElements = document.querySelectorAll('.news-section .news-content-wrp');
  slideshowElements.forEach(element => {
    $(element).flickity({
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
      watchCSS: true,
    });
  });
}
