/*
  Creates the functionality of of news items
*/

export function loadMore() {
  $('.news-listing-section .news-item-wrp').slice(0, 12).show();
  if ($('.news-listing-section .news-item-wrp:hidden').length != 0) {
    $('#loadMoreDesktop').show();
  } else {
    $('#loadMoreDesktop').hide();
  }
  $('#loadMoreDesktop').on('click', function (e) {
    e.preventDefault();
    $('.news-listing-section .news-item-wrp:hidden').slice(0, 6).slideDown();
    if ($('.news-listing-section .news-item-wrp:hidden').length == 0) {
      $('#loadMoreDesktop').text('No More to view').fadeOut('slow');
      $('#loadMoreDesktop').addClass('load-more-end');
    }
  });
}