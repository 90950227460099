/*
  Delay home banner video file for 3 seconds
*/

export function delayHomeBanner() {
  setTimeout(function() {
    $('.media-wrp' ).each(function() {
      if ($(this).children('.video-wrp').length > 0) {
        $(this).find('.thumbnail-wrp').css('opacity', 0);
        $(this).find('.banner-video-item').css('opacity', 1);
        $(this).find('.banner-video-item').get(0).play();
      }
    });
  }, 3000);
}