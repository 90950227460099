import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';

import { cookieConsent } from './scripts/cookie/cookieConsent';
import { deleteCookie } from './scripts/cookie/deleteCookie';
import { fullWidthVideoPlayerFile } from './scripts/video/fullWidthVideoPlayerFile';
import { videoPopupPlayerFile } from './scripts/video/videoPopupPlayerFile';
import { delayHomeBanner } from './scripts/video/delayHomeBanner';
import { headerScripts } from './scripts/header/headerScripts';
import { stickyMenu } from './scripts/header/stickyMenu';
import { loadMore } from './scripts/load-more/loadMore';
import { formScripts } from './scripts/form/formScripts';
import { cardSectionGridSlider } from './scripts/card/cardSectionGridSlider';
import { cardSectionSlider } from './scripts/card/cardSectionSlider';
import { logoSlider } from './scripts/logo/logoSlider';
import { logoSectionGridSlider } from './scripts/logo/logoSectionGridSlider';
import { newsSectionGridSlider } from './scripts/news/newsSectionGridSlider';
import { gallerySectionSlider } from './scripts/gallery/gallerySectionSlider';
import { jqueryViewer } from './scripts/gallery/jqueryViewer';
import { editorContentAdditions } from './scripts/misc/editorContentAdditions';
import { testimonialSlider } from './scripts/testimonial/testimonialSlider';
import { testimonialGridSlider } from './scripts/testimonial/testimonialGridSlider';
import { addServicesFunctionality } from './scripts/custom/addServicesFunctionality';
import { addProcessFunctionality } from './scripts/custom/addProcessFunctionality';
import { teamMembersSlider } from './scripts/team-members/teamMembersSlider';
import { addFCPCountUp } from './scripts/custom/addFCPCountUp';
import { oneColumnScripts } from './scripts/one-column/oneColumnScripts';

// Flickity.prototype._createResizeClass = function() {
//   this.element.classList.add('flickity-resize');
// };

// Flickity.createMethods.push('_createResizeClass');

// var resize = Flickity.prototype.resize;
// Flickity.prototype.resize = function() {
//   this.element.classList.remove('flickity-resize');
//   resize.call( this );
//   this.element.classList.add('flickity-resize');
// };

export default {
  init() {
    // JavaScript to be fired on all pages
    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    addFCPCountUp();
    addProcessFunctionality();
    addServicesFunctionality();
    logoSlider();
    cookieConsent();
    deleteCookie('PREF');
    headerScripts();
    stickyMenu();
    logoSectionGridSlider();
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    gallerySectionSlider();
    jqueryViewer('.gallery-images-viewer-v2');
    jqueryViewer('.gallery-images-viewer-v1');
    fullWidthVideoPlayerFile();
    videoPopupPlayerFile();
    delayHomeBanner();
    editorContentAdditions();
    loadMore();
    testimonialGridSlider();
    testimonialSlider();
    buttonArrow();
    newsSectionGridSlider();
    flickitySliderArrowPosition();
    teamMembersSlider();
    oneColumnScripts();

  },
};

$(window).on('resize', function () {
  // flickity resize to work when specific amount of pizels are resized eg: now at 10px
  const resizeDelta = Math.abs($(window).width() - $(window).data('previousWidth'));

  if (resizeDelta > 10) {
    // $('.cards-section.slider .cards-wrp, .cards-section.grid .cards-wrp, .gallery-section.v1 .gallery-wrapper, .testimonials-section .testimonials-row'
    // ).flickity('resize');

    $('.flickity-viewport').css('height', '400');
  }

  $(window).data('previousWidth', $(window).width());
  // flickity resize to work when specific amount of pizels are resized eg: now at 10px

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());

  flickitySliderArrowPosition();
});

$(window).on('load', function () {
  $('.cards-section.slider .cards-wrp, .cards-section.grid .cards-wrp, .gallery-section.v1 .gallery-wrapper, .testimonials-section .testimonials-row, .news-section .news-content-wrp, .logo-section .logo-slider-wrp'
  ).flickity('resize');
});


function buttonArrow() {
  $('.btn-primary').each(function() {
    $(this).html('<span>' + $(this).html() + '</span>');
    $(this).append('<span class="arrow-icon"><span></span></span>');
  });
}


// Seperate function to manage slider contollers to append inside a custom wrp and avoid resize/console errors
function flickitySliderArrowPosition() {
  // Settime out function to delay function and trgger after flickity has initialized
  setTimeout(function() {
    $('.testimonials-section ').each(function() {
      // Check if flickity is initialzed
      if ($('.testimonials-section .flickity-enabled').length) {
        // clone flickity default controllers to the custom wrapper 'controller-wrp'
        $(this).find('.flickity-button').appendTo($(this).find('.paginaion-button-desk'));
      }
    });
  },400);
}