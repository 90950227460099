/*
  Add functionality to Process Section
*/

export function addProcessFunctionality() {
  let initialtarget = $('.how-it-wrorks-section .items-wrp .img-wrp').first().data('target');
  $(`#${initialtarget}`).slideDown();
  $(`#${initialtarget}`).addClass('active');
  $(`[data-target='${initialtarget}']`).each(function() {
    $(this).addClass('active');
  });

  $('.how-it-wrorks-section .items-wrp .img-wrp').click(function() {
    let target = $(this).data('target');

    if (!$(`#${target}`).hasClass('active')) {
      $('.how-it-wrorks-section .items-wrp .description-slide').each(function() {
        if(matchMedia('screen and (max-width: 768px)').matches) {
          $(this).slideUp();
          $(this).removeClass('active').addClass('d-none');
        } else {
          $(this).removeClass('active').addClass('fade-out').removeClass('fade-in').addClass('d-none').removeClass('d-block');
        }

      });

      $('.how-it-wrorks-section .items-wrp .img-wrp').each(function() {
        $(this).removeClass('active');
      });

      $(`[data-target='${target}']`).each(function() {
        $(this).addClass('active');
      });

      if(matchMedia('screen and (max-width: 768px)').matches) {
        $(`#${target}`).slideDown();
        $(`#${target}`).addClass('active').removeClass('d-none');
      } else {
        $(`#${target}`).addClass('active').addClass('fade-in').removeClass('fade-out').removeClass('d-none').addClass('d-block');
      }
    }
  });
}