/* Logo section grid slider */

import Flickity from 'flickity';

export function logoSectionGridSlider() {
  // $('.logo-section .logo-grid').flickity({
  //   pageDots: true,
  //   prevNextButtons: false,
  //   wrapAround: true,
  //   adaptiveHeight: true,
  //   watchCSS: true,
  // });
  const slideshowElements = document.querySelectorAll('.logo-section .logo-grid');
  slideshowElements.forEach(element => {
    logoSectionSlider(element);
  });
}

function logoSectionSlider(slideshowEl) {
  // Play with this value to change the speed
  let tickerSpeed = 1;
  let cellAlignValue = '';

  let flickity = null;
  let isPaused = false;
  // const slideshowEl = document.querySelector('.logo-section .logo-slider');


  if (matchMedia('screen and (max-width: 1200px)').matches) {
    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      cellAlign: cellAlignValue,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      watchCSS: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}