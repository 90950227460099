/*
  One Column in FCP
*/

export function oneColumnScripts() {
  $('.one-column-section .editor-content-wrp .button-wrapper').each(function() {
    var $sibling = $(this).siblings().filter(function () {
      var style = $(this).attr('style');
      return style && style.includes('text-align: center');
    });

    if ($sibling.length) {
      $(this).css({
        display: 'flex',
        'justify-content': 'center',
      });
    }
  });
}