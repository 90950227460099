/*
  Testimonial grid slider
*/

export function testimonialGridSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.testimonials-section.grid .testimonial-slider').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    watchCSS: true,
    cellAlign: cellAlignValue,
  });
}