/*
  Testimonial slider
*/

export function testimonialSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.testimonials-section.slider .testimonial-slider').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });
}