/*
  Gallery section slider
*/

export function teamMembersSlider() {
  $('.team-members-section .team-members-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    adaptiveHeight: true,
    cellAlign: 'center',
    watchCSS: true,
  });
}